import { Component, Prop, Vue } from 'vue-property-decorator'

//wrappers
import { Modal, WColumns, WColumn, Loader, WButton, Arrow } from '@/components/wrappers'

import GroupPage from '@/views/private/group/Group.vue'

//molds
// import { User } from '@/molds/User';
import { Group } from '@/store/modules/group/group';
// import { GroupPermission } from '@/molds/GroupPermission';

//services
// import { Alert } from '@/services/notification';

//Vuex
// const namespace = 'permission';
// import { Action, Getter } from 'vuex-class';

@Component({
	components: {
		Modal,
		WColumn,
		WColumns,
		Loader,
		WButton,
		Arrow,
		GroupPage
	}
})
export default class ListOptionsGroup extends Vue { 
	@Prop() group!: Group
	@Prop() isMygroup!: boolean

	isEdit = false

	goToMyGroup(): void {
		this.$router.push(`/my-group/${this.group.idGroup}`)
		this.$emit('close')
	}
	
	goToGroup(): void {
		this.isEdit = true
		// this.$router.push(`/group/${this.group.idGroup}`)
		// this.$emit('close')
	}
}
