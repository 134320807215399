import { Component, Vue } from 'vue-property-decorator'

//components
import ListGroups from '@/components/listGroups/ListGroups.vue'
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import ListOptionsGroup from '@/components/listOptionsGroup/ListOptionsGroup.vue';

//molds
import { Group } from '@/store/modules/group/group';
import { Form } from '@/components/wrappers/molds/Form';
import { TableOptions, WolfApiData } from '@/components/wrappers/molds/TableOptions';

//classes
import { Alert } from '@/services/notification';

//Vuex
const namespace = 'group';
import { Action, Getter } from 'vuex-class';

//wrappers
import {
	WForm, 
	Container, 
	Box, 
  WButton,
  WHr,
	WSubtitle,
	WTable,
	Loader,
	WColumns,
	WColumn,
	WTabs,
	SizedBox
} from '@/components/wrappers';

@Component({
	components: {
		WHr,
		Box,
		WForm,
		WButton,
		WSubtitle,
		Container,
		PrivateContent,
		WTable,
		Loader,
		WColumns,
		WColumn,
		WTabs,
		ListGroups,
		ListOptionsGroup,
		SizedBox
	}
})
export default class GroupAdmin extends Vue {
	
	@Action('fetchGroups', { namespace }) fetchGroups: any;
	@Action('fetchMyGroups', { namespace }) fetchMyGroups: any;

	@Getter('groups', { namespace }) groups!: any;
	@Getter('myGroups', { namespace }) myGroups!: any;
	@Getter('loader', { namespace }) isLoading!: boolean;
	@Getter('error', { namespace }) error!: any;

	isSearch = false
	showTable = false
	alert = new Alert(this.$store)
	result!: WolfApiData

	paramQuery!: string
	perPage = 10

	querySearch = ""

	mounted(){
		document.title = 'Pesquisar Grupos'
		this.getMyGroups()
	}

	getMyGroups(): void {
		this.fetchMyGroups('')
		if(this.error){
			this.alert.apiMessage("Não foi possível listar os grupos.", this.error, "danger")
		}
	}
	//TODO deprecated
	clickRowGroup(group: Group): void {
    this.$router.push({ path: `/my-group/${group.idGroup}` })
  }
	
	goToGreateGroup(){
		this.$router.push("/new-group")
	}

	changePage(page: number): void {
		this.dispatchForm(`${this.paramQuery}&_page=${page}`)
	}
	
	async dispatchForm(paramQuery: string): Promise<void> {
		this.paramQuery = paramQuery
		
		await this.fetchGroups(`${paramQuery}&_limit=${this.perPage}`)
		
		if(this.error){
			this.alert.apiMessage("Não foi possível pesquisar grupos.", this.error, "danger")
		}
		else{
			if(this.groups.list.length == 0){
				this.alert.warning("Nenhum grupo foi encontrado.")
			}else{
				window.scroll({
					top: 500,
					behavior: 'smooth'
				});
			}
			this.isSearch = true
		}
	}

	startTable(): void {
		this.showTable = true
	}

	tableSelected(selectGroup: Group){
		this.$router.push({ path: `/my-group/${selectGroup.idGroup}` })
		//TODO deprecated
		//this.openModal(selectGroup)		
	}

	verifyMyGroup(selectGroup: Group): boolean {
		let resp = false
		this.myGroups.forEach((group: Group) => {
			if(group.idGroup == selectGroup.idGroup){
				resp = true
				return true
			}
		});
		return resp
	}

	getStatusName(status: string): string {
		switch (status) {
			case "0":
				return 'Desativado'
			case "1":
				return 'Ativo'
			case "2":
			default:
				return status
		}
	}

	get convertStatus(): WolfApiData {
		const list = []
		let group!: Group
		for (let i = 0; i < this.groups.list.length; i++) {
			group = this.groups.list[i]
			group.status = this.getStatusName(group.status)
			list.push(group)
		}

		this.result = this.groups
		this.result.list = list;

		return this.result
	}

	//TODO deprecated
	openModal(group: Group): void {
    this.$buefy.modal.open({
      parent: this,
      component: ListOptionsGroup,
      props: {
        group: group,
				isMygroup: this.verifyMyGroup(group)
      },
			events:{
				close: () => {
					this.dispatchForm(this.paramQuery)
				}
			}
    })
  }

	// ############# Form ##############
	get form(): Form {
		return {
			flex: false,
			indent: true,
			paramQuery: true,
			grid: [
				[
					{
						fieldName: "name",
						type: "text",
						label: "Nome",
						placeholder:"Nome",
						maxlength: 50,
					},
					{
						fieldName: "shortName",
						type: "text",
						label: "Abreviação",
						placeholder:"Abreviação",
						maxlength: 30,
						required: false
					},
				],
				[
					{
						fieldName: "status",
						label: "Status",
						type: "select",
						maxlength: 11,
						value: "1",
						options:[
							{
								label: "Ativo",
								value: "1"
							},
							{
								label: "Desativado",
								value: "0"
							},
							// {
							// 	label: "Todos",
							// 	value: ""
							// },
						]
					},
				],
			]
		}
	}

	// ############# Table ##############
	get	table(): TableOptions {
		return {	
			paginated: true,
			perPage: this.perPage,
			wolfApiData: this.convertStatus,
			columns: [
				{
					field: 'idGroup',
					label: 'ID',
					numeric: true,
					sortable: true
				},
				{
					field: 'name',
					label: 'Nome',
					sortable: true
				},
				{
					field: 'shortName',
					label: 'Abreviação',
					sortable: true
				},
				{
					field: 'status',
					label: 'Status',
					sortable: true
				},
			]
		}
	}
}
